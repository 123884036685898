.projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 575px) {
  .rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .projectsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    margin-top: 20px;
  }
}
