html {
  scroll-behavior: smooth;
}

.welcome {
  color: white;
  z-index: 10;
  left: 20px;
  position: absolute;
  top: 40%;
}

.subWelcome {
  margin-left: 0px;
}

.imageWidth {
  width: 344px;
}

@media only screen and (min-width: 768px) {
  .subWelcome {
    margin-left: 50px;
  }

  .imageWidth {
    width: 344px;
  }
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
}
