.ProjectContainer {
  display: flex;
  border: 2px solid black;
  padding: 5px;
  box-shadow: 2px 1px 10px 2px #888888;
  margin: 10px;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
